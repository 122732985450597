<template>
    <div :symbol="symbol" class="form-autonumeric">
        <input
            :id="id"
            ref="field"
            v-bind="$attrs"
            class="form-autonumeric__input"
            type="text"
            v-on="$listeners"
        >
        <input
            :id="id ? id + '_plain' : null"
            :name="name"
            :value="value"
            type="hidden"
        >
    </div>
</template>

<script>
    import AutoNumeric from 'autonumeric';

    const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
    const thousandsSeparator = (1000).toLocaleString().substring(1, 2);

    export default {
        props: {
            name: {
                type: String,
                default: null,
            },

            id: {
                type: String,
                default: null,
            },

            value: {
                type: [Number, String],
                default: null,
            },

            symbol: {
                type: String,
                default: null,
            },

            decimals: {
                type: Number,
                default: 10,
            },
        },

        data() {
            return {
                options: {
                    allowDecimalPadding: false,
                    decimalPlaces: this.decimals,
                    digitGroupSeparator: thousandsSeparator,
                    decimalCharacter: decimalSeparator,
                    decimalCharacterAlternative: '.',
                    modifyValueOnWheel: false,
                    onInvalidPaste: 'replace',
                    showWarnings: false,
                },
            };
        },

        watch: {
            value(value) {
                if (value) {
                    if (this.unFormat(this.$refs.field.value) !== value) {
                        this.$options.instance.set(this.getValue(value));
                    }
                } else if (value === 0) {
                    if (this.$refs.field.value !== this.format(value)) {
                        this.$options.instance.set(value);
                    }
                } else {
                    this.$options.instance.set('');
                }
            },
        },

        mounted() {
            if (this.value || this.value === 0) {
                // Pre-format
                this.$refs.field.value = this.format(this.value);
            }

            this.$options.instance = new AutoNumeric(this.$refs.field, this.options);
            this.$refs.field.addEventListener('input', () => {
                this.$emit('input', this.unFormat(this.$refs.field.value));
            });
            this.$refs.field.addEventListener('change', () => {
                this.$emit('change', this.unFormat(this.$refs.field.value));
            });
        },

        methods: {
            /**
             * @param {Number|String} value
             *
             * @returns {String}
             */
            format(value) {
                return AutoNumeric.format(this.getValue(value), this.options);
            },

            /**
             * Get the float representing the value.
             *
             * @param {Number|String} value
             *
             * @returns {Number}
             */
            getValue(value) {
                return parseFloat(value);
            },

            /**
             * @param {String} value
             *
             * @returns {Number|String}
             */
            unFormat(value) {
                const number = this.parseValue(AutoNumeric.unformat(value, this.options));

                return isNaN(number) ? '' : number;
            },

            /**
             * Parse the unformatted value back to the original float value.
             *
             * @param {String} value
             *
             * @returns {Number}
             */
            parseValue(value) {
                return parseFloat(value);
            },
        },

        instance: null,
    };
</script>
