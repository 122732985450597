<script>
    import Base from './AutoNumeric.vue';

    export default {
        extends: Base,

        data() {
            return {
                options: {
                    allowDecimalPadding: true,
                    decimalPlaces: 2,
                },
            };
        },

        methods: {
            /**
             * Get the float representing the value.
             *
             * @param {Number|String} value
             *
             * @returns {Number}
             */
            getValue(value) {
                return parseInt(value, 10) / 100;
            },

            /**
             * Parse the unformatted value back to the original float value.
             *
             * @param {String} value
             *
             * @returns {Number}
             */
            parseValue(value) {
                return Math.round(parseFloat(value) * 100);
            },
        },
    };
</script>
